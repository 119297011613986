<template>
    <div class="dashboard-container">
        <div style="text-align: end">
            <h2 style="margin: 0">สวัสดี Admin</h2>
            <h4 style="margin: 0">เครดิตในระบบ: {{ sum_all() }} THB</h4>
            <a href="/admin/main">ไปหน้าหลัก</a>
        </div>

        <a-card style="margin: 1rem">
            <a-row>
                <a-divider orientation="right">ผู้เล่น</a-divider>
                <a-col :span="12">
                    <a-card>
                        <a-date-picker placeholder="เปลี่ยนวัน" @change="setDate" />
                        <a-statistic :title="`ยอดสมัครวัน ${selected_day}`" :value="today_register()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>คน</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card>
                        <a-date-picker placeholder="เปลี่ยนเดือน" picker="month" @change="setMonth" />
                        <a-statistic :title="`ยอดสมัครเดือน ${selected_month}`" :value="month_register()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>คน</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    <a-card>
                        <a-statistic :title="`ยอดผู้เล่นวันนี้`" :value="today_users()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>คน</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card>
                        <a-statistic :title="`ยอดผู้เล่นทั้งหมด`" :value="users.length" style="margin-right: 50px" >
                            <template #suffix>
                                <span>คน</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
            </a-row>
        </a-card>

        <a-card style="margin: 1rem">
            <a-row>
                <a-divider orientation="right">ยอดถอน</a-divider>
                <a-col :span="12">
                    <a-card>
                        <a-date-picker placeholder="เปลี่ยนวัน" @change="setDate" />
                        <a-statistic :title="`ยอดถอนวัน ${selected_day}`" :value="today_withdraw()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>THB</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card>
                        <a-date-picker placeholder="เปลี่ยนเดือน" picker="month" @change="setMonth" />
                        <a-statistic :title="`ยอดถอนเดือน ${selected_month}`" :value="month_withdraw()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>THB</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
            </a-row>
        </a-card>

        <a-card style="margin: 1rem">
            <a-row>
                <a-divider orientation="right">ยอดเล่น</a-divider>
                <a-col :span="12">
                    <a-card>
                        <a-date-picker placeholder="เปลี่ยนวัน" @change="setDate" />
                        <a-statistic :title="`ยอดเล่นวัน ${selected_day}`" :value="today_income()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>THB</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card>
                        <a-date-picker placeholder="เปลี่ยนเดือน" picker="month" @change="setMonth" />
                        <a-statistic :title="`ยอดเล่นเดือน ${selected_month}`" :value="month_income()" style="margin-right: 50px" >
                            <template #suffix>
                                <span>THB</span>
                            </template>
                        </a-statistic>
                    </a-card>
                </a-col>
            </a-row>
        </a-card>
    </div>
</template>

<script>
// import Swal from 'sweetalert2'
import moment from 'moment'
import dayjs from 'dayjs'
import AdminService from '../../services/AdminService'
export default {
    name: 'dashboard-agent',
    data() {
        return {
            profile: 'Admin',
            users: [],
            history: [],
            income: [],
            selected_day: moment().format('DD/MM/YYYY'),
            selected_month: moment().format('MM/YYYY')
        }
    },
    methods: {
        sum_all() {
            const user = this.users.filter(({ balance }) => balance >= 0).map(({ balance }) => balance).reduce((a, b) => a + b, 0)
            return user.toLocaleString()
        },
        today_users() {
            return this.users.filter(({ updated_at }) => moment(updated_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') == this.selected_day).length
        },
        today_withdraw() {
            return this.history.filter(({ date }) => date == this.selected_day).map(({ action }) => Number(action)).reduce((a,b) => a+b, 0)
        },
        month_withdraw() {
            return this.history.filter(({ month }) => month == this.selected_month).map(({ action }) => Number(action)).reduce((a,b) => a+b, 0)
        },
        today_income() {
            return this.income.filter(({ date }) => date == this.selected_day).map(({ action }) => Number(action)).reduce((a,b) => a+b, 0).toLocaleString()
        },
        month_income() {
            return this.income.filter(({ month }) => month == this.selected_month).map(({ action }) => Number(action)).reduce((a,b) => a+b, 0).toLocaleString()
        },
        today_register() {
            return this.users.filter(({ created_at }) => moment(created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') == this.selected_day).length
        },
        month_register() {
            return this.users.filter(({ created_at }) => moment(created_at, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY') == this.selected_month).length
        },
        setMonth(e) {
            this.selected_month = dayjs(e).format('MM/YYYY')
        },
        setDate(e) {
            this.selected_day = dayjs(e).format('DD/MM/YYYY')
        },
        async get_admin_profile() {
            const { users, staffs } = await AdminService.profile()
            this.users = users
            this.history = staffs[0].history
                .filter(({ action }) => action.split('-')[0] == 'Allow')
                .map(({ action, created_at }) => {
                    return {
                        action: action.split('-')[3],
                        date: moment(created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
                        month: moment(created_at, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY'),
                    }
                })
        },
        async get_income() {
            this.income = await AdminService.get_income()
        },
        check_agent() {
            if (!localStorage.getItem('admin')) {
                this.$router.push('/')
            }
        },
    },
    async mounted() {
        this.check_agent()
        await this.get_admin_profile()
        this.get_income()
    }
}
</script>

<style lang="less" scoped>
    .ant-col {
        padding: 1rem
    }
    h2, h4 {
        color: #fce285
    }
    .bank-id {
        cursor: pointer;
    }
    h3 {
        font-size: 16px
    }

    .regis-input {
        display: flex;
        flex-direction: column;
        justify-content: center;

        input {
            margin: 0.25rem
        }

        input::placeholder {
            color: #fce285
        }
    }

    .dashboard-container {
        padding: 0.5rem;
        flex-direction: column;
        display: flex;
    }

    .dashboard-content {
        width: 100%;
        padding: 0.5rem;
    }

    .table-dashboard {
        display: flex;
        justify-content: space-evenly;

        table {
            max-height: 20rem;
            padding: 0.5rem
        }

        .list-container {
            .table-text {
                text-align: center;
                font-size: 14px
            }

            padding: 0.5rem;
            height: 20rem;
            overflow-y: scroll;
            border: 1px solid #fce285;
            border-radius: 5px;
        }
    }

    .create-account {
        display: flex;
        justify-content: center;
        width: 100%;

        .login-input {
            display: flex;
            flex-direction: column;
        }
}
</style>
